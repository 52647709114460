import { Box, Card, Slide, Stack, Typography } from "@mui/material";
import { RichTreeView } from "@mui/x-tree-view/RichTreeView";
import { useTreeViewApiRef } from "@mui/x-tree-view/hooks/useTreeViewApiRef";
import {
  DocumentsTreeItem,
  DocumentsTreeItemProps,
  DocumentsTreeItemValue,
} from "./DocumentsTreeItem";
import {
  ActionButton,
  AutoEllipsis,
  ConfirmationModal,
  ContractSearchField,
  EContractState,
  IContractSummary,
  IDocument,
  Page,
  PageHeader,
  TextButton,
  useAccount,
  useConfirmationModal,
  useContractsSummary,
  useDocuments,
  useDocumentsMutations,
  useDownloadUrl,
  useModal,
  useSearchParamsContract,
  useSearchParamsForm,
  useToast,
  ContractSearchOption,
  ButtonRow,
} from "@synota-io/synota-shared-ui";
import { PostAdd } from "@mui/icons-material";
import { SyntheticEvent, useEffect, useMemo, useRef, useState } from "react";
import {
  UploadDocumentFormModal,
  UploadDocumentModalState,
} from "../modals/UploadDocumentFormModal";
import { BackToContractButton } from "../../contracts/components/BackToContractButton";

function documentsToTreeView({
  document,
  parentId,
  onDelete,
  onDownload,
}: {
  document: IDocument;
  parentId?: string;
  onDelete?: (event: React.MouseEvent<HTMLElement, MouseEvent>, documentUuid: string) => void;
  onDownload?: (event: React.MouseEvent<HTMLElement, MouseEvent>, documentUuid: string) => void;
}): DocumentsTreeItemValue {
  return {
    id: parentId + document.uuid,
    label: document.original_filename,
    document: document,
    onDeleteClick: onDelete && ((e) => onDelete(e, document.uuid)),
    onDownloadClick: onDownload && ((e) => onDownload(e, document.uuid)),
  };
}

function getLatestDate(item: DocumentsTreeItemValue): Date {
  if (!item.children?.length) {
    return new Date(0);
  }

  const childDocuments = item.children
    .filter((child) => child.document?.created_at)
    .map((child) => new Date(child.document!.created_at));

  return childDocuments.length
    ? new Date(Math.max(...childDocuments.map((d) => d.getTime())))
    : new Date(0);
}

function sortByLatestChildDocument(a: DocumentsTreeItemValue, b: DocumentsTreeItemValue): number {
  const latestA = getLatestDate(a);
  const latestB = getLatestDate(b);
  return latestB.getTime() - latestA.getTime();
}

interface ContractsToTreeParams {
  contract: IContractSummary;
  documents: IDocument[];
  onUpload?: (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    contractUuid: string,
    file?: File,
  ) => void;
  onDelete?: (event: React.MouseEvent<HTMLElement, MouseEvent>, documentUuid: string) => void;
  onDownload?: (event: React.MouseEvent<HTMLElement, MouseEvent>, documentUuid: string) => void;
}

function contractsToTreeView({
  contract,
  documents,
  onUpload,
  onDelete,
  onDownload,
}: ContractsToTreeParams): DocumentsTreeItemValue {
  const contractDocs = documents.filter((document) => document.contract_uuid === contract.uuid);
  return {
    id: contract.uuid,
    label: contract.name,
    disabled: !documents.length,
    children: contractDocs.length
      ? contractDocs.map((document) =>
          documentsToTreeView({ document, parentId: contract.uuid, onDelete, onDownload }),
        )
      : [
          {
            label: "No files yet.",
            id: contract.uuid + "_no_files_yet",
            onUploadClick: onUpload && ((e) => onUpload(e, contract.uuid)),
          } as DocumentsTreeItemValue,
        ],
    onUploadClick: onUpload && ((e) => onUpload(e, contract.uuid)),
    onDropFile: onUpload && ((e) => onUpload(e, contract.uuid, e.dataTransfer?.files?.[0])),
  };
}

export const DocumentsPage = () => {
  const { jwt, isAdmin } = useAccount();
  const { contracts } = useContractsSummary();

  const form = useSearchParamsForm<{
    contract: ContractSearchOption;
  }>(
    {
      defaultValues: { contract: null },
    },
    { contract: useSearchParamsContract() },
  );

  const toast = useToast();
  const apiRef = useTreeViewApiRef();
  const contractRef = useRef<string>("");
  const uploadModal = useModal<UploadDocumentModalState>();
  const deleteModal = useConfirmationModal();
  const values = form.watch();

  const { documents, isLoading, refetch, error } = useDocuments();
  const { deleteDocument, downloadDocument, isPendingDelete, isPending } = useDocumentsMutations();

  const download = useDownloadUrl();

  const itemActions: Partial<ContractsToTreeParams> = useMemo(
    () => ({
      onDownload: (event, uuid) => {
        event.stopPropagation();
        const find = documents.find((i) => i.uuid === uuid);
        if (!find || !find.contract_uuid) {
          return;
        }
        downloadDocument(
          { jwt, document_uuid: uuid, contract_shared_uuid: find.contract_uuid },
          {
            onSuccess: (data) => {
              download({ url: data.data, filename: find.original_filename });
            },
          },
        );
      },
    }),
    [documents, download, downloadDocument, jwt],
  );

  const itemAdminActions: Partial<ContractsToTreeParams> = useMemo(
    () =>
      isAdmin
        ? {
            onUpload: (event, uuid, file) => {
              event.stopPropagation();
              uploadModal.onOpen({ uuid, file });
            },
            onDelete: (event, uuid) => {
              event.stopPropagation();
              const filename = documents.find((i) => i.uuid === uuid)?.original_filename || "";
              deleteModal.onOpen({
                acceptLabel: "Delete",
                title: "Delete file?",
                body: <AutoEllipsis disableTooltip>{filename}</AutoEllipsis>,
                onConfirm: () => {
                  deleteDocument(
                    { jwt, document_uuid: uuid },
                    {
                      onSuccess: () => {
                        toast.success("Document deleted successfully.");
                        deleteModal.onClose();
                        refetch();
                      },
                    },
                  );
                },
              });
            },
          }
        : {},
    [deleteDocument, deleteModal, documents, isAdmin, jwt, refetch, toast, uploadModal],
  );

  const items = useMemo(() => {
    if (contracts) {
      return contracts
        .filter((contract) => contract.status !== EContractState.Unsigned)
        .map((contract) => {
          return contractsToTreeView({
            contract,
            documents,
            ...itemActions,
            ...itemAdminActions,
          });
        })
        .sort(sortByLatestChildDocument);
    }

    return [];
  }, [contracts, documents, itemActions, itemAdminActions]);

  const [expandedItems, setExpandedItems] = useState<string[]>([]);

  const handleExpandedItemsChange = (_event: SyntheticEvent, itemIds: string[]) => {
    setExpandedItems(itemIds);
  };

  useEffect(() => {
    const itemId = values.contract?.value || "";
    if (apiRef.current && itemId && itemId !== contractRef.current) {
      contractRef.current = itemId;

      setExpandedItems([itemId]);
      apiRef.current?.selectItem({
        event: {} as SyntheticEvent,
        itemId,
        keepExistingSelection: false,
      });
    }
  }, [apiRef, items, values]);

  const onUploadModalOpen = () => {
    uploadModal.onOpen({});
  };

  return (
    <Page>
      <PageHeader
        title="Documents"
        actions={
          <ButtonRow>
            <BackToContractButton {...values.contract} />
            {isAdmin ? (
              <ActionButton
                onClick={onUploadModalOpen}
                size="small"
                color="primary"
                title="Upload Document"
              >
                <PostAdd />
              </ActionButton>
            ) : null}
          </ButtonRow>
        }
      >
        <ContractSearchField
          label="Search Contract"
          fullWidth
          variant="filled"
          size="small"
          control={form.control}
          name="contract"
        />
      </PageHeader>
      <Box pt={5}>
        <Slide in={!isLoading} direction="up">
          <Card sx={{ p: 2 }}>
            {items.some(
              (i) => i.children?.filter((c: DocumentsTreeItemValue) => c.document)?.length,
            ) ? (
              <RichTreeView
                apiRef={apiRef}
                items={items}
                slots={{
                  item: DocumentsTreeItem,
                }}
                slotProps={{
                  item: { isLoading: isPending } as DocumentsTreeItemProps,
                }}
                expandedItems={expandedItems}
                onExpandedItemsChange={handleExpandedItemsChange}
              />
            ) : (
              <Stack py={2} alignItems="center">
                {error ? (
                  <Typography color="error">There was a problem loading the documents.</Typography>
                ) : (
                  <>
                    <Typography>There are no documents yet.</Typography>
                    <TextButton onClick={onUploadModalOpen} color="primary">
                      Upload the first document
                    </TextButton>
                  </>
                )}
              </Stack>
            )}
          </Card>
        </Slide>
      </Box>
      {uploadModal.open ? (
        <UploadDocumentFormModal
          onSuccess={(data) => {
            toast.success("Document uploaded successfully.");
            refetch().then(() => {
              if (data.data.contract_uuid) {
                setExpandedItems([data.data.contract_uuid]);
                apiRef.current?.selectItem({
                  event: {} as SyntheticEvent,
                  itemId: data.data.contract_uuid + data.data.uuid,
                  keepExistingSelection: false,
                });
              }
            });

            uploadModal.onClose();
          }}
          {...uploadModal}
        />
      ) : null}
      {deleteModal.open ? <ConfirmationModal isLoading={isPendingDelete} {...deleteModal} /> : null}
    </Page>
  );
};
